import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  name?: string;
}

export const Seo: React.FC<Props> = ({
  title = 'Self-growth book | Lasting Change',
  description = 'The Lasting Change is the first fully personalized self-growth and wellness book in the world. Created for you and about you.',
  name = 'Lasting Change: Self-Growth Book',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />

    {/* FB tags */}
    <meta property="og:url" content="https://thelastingchange.com" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
