import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Api } from '../../apis/api';
import { handleError } from '../../utils/error';
import { setExperiments, setProducts, setQuestions } from './actions';
import { IExperiments } from './types';

export const fetchProducts =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.fetchProducts()
      .then(products => dispatch(setProducts(products)))
      .catch(handleError);

export const fetchQuiz =
  (variant?: string) => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.fetchQuiz(variant)
      .then(({ questions }) => dispatch(setQuestions(questions)))
      .catch(handleError);
// .then(({ questions }) => dispatch(setQuestions(temp)))

export const updateExperiments =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    let experiments: IExperiments = {};

    if (typeof window !== `undefined`) {
      const params = new URLSearchParams(window.location?.search);
      const testId = params.get('test');
      const flow = params.get('flow');

      if (testId) {
        experiments[testId] = true;
      }

      if (flow) {
        experiments[flow] = true;
      }
    }

    dispatch(setExperiments(experiments));
  };
